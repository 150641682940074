exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-grants-js": () => import("./../../../src/pages/grants.js" /* webpackChunkName: "component---src-pages-grants-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pl-dotacje-js": () => import("./../../../src/pages/pl/dotacje.js" /* webpackChunkName: "component---src-pages-pl-dotacje-js" */),
  "component---src-pages-pl-dziekujemy-js": () => import("./../../../src/pages/pl/dziekujemy.js" /* webpackChunkName: "component---src-pages-pl-dziekujemy-js" */),
  "component---src-pages-pl-index-js": () => import("./../../../src/pages/pl/index.js" /* webpackChunkName: "component---src-pages-pl-index-js" */),
  "component---src-pages-pl-polityka-cookie-js": () => import("./../../../src/pages/pl/polityka-cookie.js" /* webpackChunkName: "component---src-pages-pl-polityka-cookie-js" */),
  "component---src-pages-pl-polityka-prywatnosci-js": () => import("./../../../src/pages/pl/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-pl-polityka-prywatnosci-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

